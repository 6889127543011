@font-face {
  font-family: "Poppins";
  src: url("./fonts/poppins/Poppins-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/poppins/Poppins-Regular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/poppins/Poppins-Regular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/poppins/Poppins-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/poppins/Poppins-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/poppins/Poppins-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/poppins/Poppins-Bold.otf") format("opentype"), /* Open Type Font */
    url("./fonts/poppins/Poppins-Bold.svg") format("svg"), /* Legacy iOS */
    url("./fonts/poppins/Poppins-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/poppins/Poppins-Bold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/poppins/Poppins-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./fonts/poppins/Poppins-Light.eot"); /* IE9 Compat Modes */
  src: url("./fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/poppins/Poppins-Light.otf") format("opentype"), /* Open Type Font */
    url("./fonts/poppins/Poppins-Light.svg") format("svg"), /* Legacy iOS */
    url("./fonts/poppins/Poppins-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/poppins/Poppins-Light.woff") format("woff"), /* Modern Browsers */
    url("./fonts/poppins/Poppins-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/helvetica/Helvetica.eot"); /* IE9 Compat Modes */
  src: url("./fonts/helvetica/Helvetica.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/helvetica/Helvetica.otf") format("opentype"), /* Open Type Font */
    url("./fonts/helvetica/Helvetica.svg") format("svg"), /* Legacy iOS */
    url("./fonts/helvetica/Helvetica.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/helvetica/Helvetica.woff") format("woff"), /* Modern Browsers */
    url("./fonts/helvetica/Helvetica.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Raleway-Light";
  src: url("./fonts/raleway/Raleway-Light.eot"); /* IE9 Compat Modes */
  src: url("./fonts/raleway/Raleway-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/raleway/Raleway-Light.otf") format("opentype"), /* Open Type Font */
    url("./fonts/raleway/Raleway-Light.svg") format("svg"), /* Legacy iOS */
    url("./fonts/raleway/Raleway-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/raleway/Raleway-Light.woff") format("woff"), /* Modern Browsers */
    url("./fonts/raleway/Raleway-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #f7f7f7;
}

body ::selection {
  background: #3e6e31; /* WebKit/Blink Browsers */
}

body ::-moz-selection {
  background: #3e6e31; /* Gecko Browsers */ 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form:valid input[type="submit"] {
  background-color: #cfc;
  color: #060;
}

form:invalid input[type="submit"] {
  background-color: #fcc;
  color: #600;
}

